.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3%;
  margin-bottom: -6%;
  background-color: white;
  /* box-shadow: 2px 2px 3px 4px; */
  width: 72%;
  margin-left: 16%;
  gap: 10px;
  box-shadow: 3px 2px 4px 6px whitesmoke;
  border-radius: 40px;
}

.choose {
  background-color: skyblue;
  border-radius: 50px;
  padding: 8px;
  border-left: 50%;
  border-left: 50%;
}

.digit {
  border-radius: 50%;
  padding: 3px;
  background-color: white;
}

.digit1 {
  border-radius: 50%;
  padding: 3px;
  background-color: rgb(212, 229, 236);
  margin-right: 6px;
}
.choose1 {
  margin-top: 1%;
  margin-left: 20px;
}
