* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.basic {
  display: flex;
}

.form-data1 {
  display: flex;
  margin: 2%;
  padding: 2%;
  margin-right: -25%;
  justify-content: space-between;
}

.navbar {
  padding: 1%;
  background-color: aliceblue;
  width: 80%;
  margin-left: 7%;
}
.input {
  width: 50vh;
  height: 5vh;
}
.main {
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  width: 100%;
}
.form-container {
  margin: 10%;
  margin-left: 10%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 70%;
}

.form-container1 {
  margin: 8%;

  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;
}

h4 {
  /* padding: 1%; */
  /* border: 1px solid bisque; */

  margin-left: 0;
  padding: 1%;
  padding-left: 0;
  margin: 0;
  margin-top: 3%;
}
.p-data {
  margin-left: 10%;
  margin-bottom: -7%;
  margin-top: 8%;
}

.button {
  margin-top: -12%;

  margin-left: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;
}

.btn1 {
  height: 6vh;
  border-radius: 40px;
  color: white;
  width: 15vh;
  background-color: skyblue;
  cursor: pointer;
  border: none;
  box-shadow: 2px 2px rgb(211, 211, 211);
}

.btn2 {
  height: 6vh;
  border-radius: 25px;
  background-color: rgb(66, 66, 238);
  cursor: pointer;
  color: white;
  padding: 2%;
  width: 25vh;
  border: none;
  margin-left: 4%;

  box-shadow: 2px 2px rgb(211, 211, 211);
}
.rectangle {
  height: 3px;
  width: calc(100% - 0rem);
  background: rgba(225, 249, 244, 1);
  border-radius: 0px;

  background: #e1f9f4;
}
